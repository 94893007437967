import React, { Fragment, useEffect, useState } from 'react';
import { withDark } from 'src/contexts/dark-context';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { NavBarBrand } from 'src/interface/basics/navbars';
import { Nav } from 'src/interface/basics/navs';
import { Footer, FooterHeader, FooterBody, FooterItem, FooterTitle, FooterBtn, FooterLink, FooterFooter } from 'src/interface/basics/footers';
import { BtnLink, ExternalLink } from 'src/interface/basics/links';
import { FabIcon, FalIcon } from 'src/interface/vendors/fa-wrapper';
import { BrandLogo, BrandLogoDark } from 'src/assets';

const MktFooter = (props) => {
  const { darkContext = {}, navContext = {} } = props;
  const { dark, toggleDark } = darkContext;

	return (
      <Fragment>
  		<Footer className='bg-gray-50 py-2'>
        <Container>
          <FooterBody>
            <Row className='w-100'>
              <Col md={12} lg={5}>
                <Nav className='flex-column pb-5'>
                  <FooterItem className=''>
                    <NavBarBrand src={dark ? BrandLogoDark : BrandLogo} className='' />
                  </FooterItem>
                  <FooterItem className='py-3'>
                    <p className='reg gray-700'>We're a software development team based in Denver, Colorado that specializes in web, mobile, and cloud computing apps.</p>
                  </FooterItem>
                </Nav>
              </Col>
              <Col md={12} lg={{ span: 7}}>
                <Row>
                  <Col xs={6} md={4}>
                    <Nav className='flex-column pb-5'>
                      <FooterItem className='pb-3'><FooterTitle>Company</FooterTitle></FooterItem>
                      <FooterItem className='py-1'><FooterLink to='/about'>About us</FooterLink></FooterItem>
                      <FooterItem className='py-1'><FooterLink to='/playbooks'>Our Playbooks</FooterLink></FooterItem>
                      <FooterItem className='py-1'><FooterLink to='/services'>Our Services</FooterLink></FooterItem>
                      <FooterItem className='py-1'><FooterLink to='/work'>Our Work</FooterLink></FooterItem>
                    </Nav>
                  </Col>
                  <Col xs={6} md={4}>
                    <Nav className='flex-column pb-5'>
                      <FooterItem className='pb-3'><FooterTitle>Other Resources</FooterTitle></FooterItem>
                      <FooterItem className='py-1'><ExternalLink href='https://medium.com/mile-hi-labs' className='btn footer-link'>Blog</ExternalLink></FooterItem>
                      <FooterItem className='py-1'><FooterLink to='/jobs'>Jobs</FooterLink></FooterItem>
                      <FooterItem className='py-1'><FooterLink to='/contact'>Contact us</FooterLink></FooterItem>
                    </Nav>
                  </Col>
                  <Col xs={6} md={4}>
                    <Nav className='flex-column pb-5'>
                      <FooterItem className='pb-3'><FooterTitle>Social</FooterTitle></FooterItem>
                      <FooterItem className='py-1'><ExternalLink href='https://github.com/mile-hi-labs' className='btn footer-link'>Github</ExternalLink></FooterItem>
                      <FooterItem className='py-1'><ExternalLink href='https://medium.com/mile-hi-labs' className='btn footer-link'>Medium</ExternalLink></FooterItem>
                      <FooterItem className='py-1'><ExternalLink href='https://linkedin.com/in/erichubbell' className='btn footer-link'>LinkedIn</ExternalLink></FooterItem>
                    </Nav>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FooterBody>
          <FooterFooter className='text-left'>
            <small className='gray-600 med'>© 2021 Mile Hi Labs, LLC</small>
          </FooterFooter>
        </Container>
    	</Footer>
      </Fragment>
	);
}

export default withDark(MktFooter);

