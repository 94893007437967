import React from 'react';
import { Link as GLink } from 'gatsby';
import { AnchorLink as ALink } from 'gatsby-plugin-anchor-links';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';

const BtnLink = ({ to = '/', className = 'btn-primary', children }) => (
  <GLink to={to} className={`btn ${className}`}>
  	{children}
	</GLink>
)

const BtnPrevLink = ({ to = '/', className = 'btn-primary', children }) => (
  <GLink to={to} className={`btn ${className}`}>
    <span><FalIcon icon='long-arrow-left' className='mr-2'/>{children}</span>
  </GLink>
)

const BtnNextLink = ({ to = '/', className = 'btn-primary', children }) => (
  <GLink to={to} className={`btn ${className}`}>
    <span>{children}<FalIcon icon='long-arrow-right' className='ml-2'/></span>
  </GLink>
)

const Link = ({ to = '/', className = 'link-primary', children }) => (
  <GLink to={to} className={`link ${className}`}>
    {children}
  </GLink>
)

const PrevLink = ({ to = '/', className = 'link-primary', children }) => (
  <GLink to={to} className={`btn ${className}`}>
    <span><FalIcon icon='long-arrow-left' className='mr-2'/>{children}</span>
  </GLink>
)

const NextLink = ({ to = '/', className = 'link-primary', children }) => (
  <GLink to={to} className={`btn ${className}`}>
    <span>{children}<FalIcon icon='long-arrow-right' className='ml-2'/></span>
  </GLink>
)

const AnchorLink = ({ to = '/', className = '', children }) => (
  <ALink to={to} className={className}>
    {children}
  </ALink>
)

const ExternalLink = ({ href = '/', className = 'link link-primary', children }) => (
  <a href={href} target='_blank' rel='noopener' className={`${className}`}>
    {children}
  </a>
)

const InlineLink = ({ to = '/', className = 'link-primary', children }) => (
  <GLink to={to} className={`link ${className}`}>
    {children}
  </GLink>
)

export {
  AnchorLink,
  BtnLink,
  BtnPrevLink,
  BtnNextLink,
  Link,
  PrevLink,
  NextLink,
  ExternalLink,
  InlineLink
}

// Docs
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/
