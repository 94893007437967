import React from 'react';
import { Link } from 'gatsby';
import { ButtonWrapper } from 'src/interface/basics/buttons';

const Mobile = ({ className = '', children }) => (
  <div className={`mobile-block ${className}`}>
    {children}
    <MobileBg />
  </div>
)

const MobileHeader = ({ className = '', children }) => (
  <div className={`mobile-header ${className}`}>{children}</div>
)

const MobileBrand = ({ to, src, alt = 'Brand logo', className = '' }) => (
  <Link to={to}>
    <img src={src} alt={alt} className={`mobile-brand ${className}`} />
  </Link>
)

const MobileBody = ({ className = '', children }) => (
  <div className={`mobile-body ${className}`}>{children}</div>
)

const MobileItem = ({ className = '', children }) => (
  <li className={`mobile-item ${className}`}>{children}</li>
)

const MobileBtn = ({ taskRunning, onClick, className = '', children }) => (
  <ButtonWrapper onClick={onClick} className={`mobile-btn ${className}`}>
    {children}
  </ButtonWrapper>
)

const MobileLink = ({ to, className = '', children }) => (
  <Link to={to} className={`btn mobile-btn ${className}`}>
    {children}
  </Link>
)

const MobileBg = ({ className = '' }) => (
  <div className={`mobile-bg ${className}`} />
)

export {
  Mobile,
  MobileHeader,
  MobileBrand,
  MobileBody,
  MobileItem,
  MobileBtn,
  MobileLink
}
