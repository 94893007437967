import React from 'react';
import RSSwitch from 'react-switch';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';

const SwitchWrapper = ({ className = '', ...args }) => (
  <span className={`switch-wrapper ${className}`}>
    <Switch {...args}/>
  </span>
)

const Switch = ({ value = false, onChange, className = 'switch' }) => (
  <RSSwitch
  	type='text'
    height={16}
    width={50}
    handleDiameter={26}
    offColor='#9EA7B3'
    onColor='#37D2BB'
  	uncheckedIcon={false}
  	checkedIcon={false}
  	checked={value}
  	onChange={onChange}
    className={className}
	/>
)

export { SwitchWrapper, Switch };

// Docs:
// https://github.com/markusenglund/react-switch#readme
