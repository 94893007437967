import React from 'react';
import Helmet from 'react-helmet';
import { SeoIndex } from 'src/assets';

const Head = (props) => {
  const { title, description, author, keywords, siteUrl, siteImg, children } = props;
  const formattedImg = process.env.GATSBY_WEB_URL + SeoIndex;


  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='author' content={author} />
      <meta name='keywords' content={keywords} />
      <link rel="canonical" href={siteUrl} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={formattedImg} />

      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={formattedImg} />
      <meta property="twitter:card" content='summary_large_image' />

      <script src="https://kit.fontawesome.com/95c629a819.js" crossOrigin="anonymous" defer />
    </Helmet>
  )
}

Head.defaultProps = {
  title: 'Mile Hi Labs',
  author: '@milehilabs',
  favicon: 'src/assets/branding/mhl-icon.jpg',
  description: "We're a software development team based in Denver Colorado that specializes in web, mobile, and cloud computing apps. If you can dream it, we can build it. So, let's get to work.",
  keywords: 'custom, software, apps, developer, agency, denver, colorado',
  siteUrl: 'https://www.milehilabs.com',
}

export default Head;
