import React from 'react';
import RBAlert from 'react-bootstrap/Alert';

const Alert = ({ className = '', children }) => {
  return (
    <RBAlert className={className}>
      {children}
    </RBAlert>
  )
}

export { Alert };

// Docs:
// https://react-bootstrap.github.io/components/alert/#api
