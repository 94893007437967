import React from 'react';
import { Alert } from 'src/interface/basics/alerts';
import { NextLink, InlineLink } from 'src/interface/basics/links';
import { TextButton } from 'src/interface/basics/buttons';
import { SwitchWrapper } from 'src/interface/vendors/switch-wrapper';
import { FadIcon, FalIcon } from 'src/interface/vendors/fa-wrapper';
import { Moon, Sun } from 'src/assets';

const DarkModeAlert = ({ title, description, dark, toggleDark, className = '', children }) => (
	<Alert className={`px-3 py-2 bg-gray-50 ${className}`}>
	  <div className='flex-between mx-auto'>
    	<div className='flex-start'>
	    	<div className='py-2 px-3 round-2 bg-gray-100 mr-3'>
	    		{dark ? <Sun className='w-20px'/> : <Moon className='w-20px fill-gray'/>}
    		</div>
    		<div className='flex-start'>
	      	{title && <p className='mbold gray-700'>{title}</p>}
      		{description && <p className='gray-700'>{description}</p>}
    			{children}
  			</div>
  		</div>
	    <div className='absolute right-3'>
	      <SwitchWrapper value={dark} uncheckedIcon='sun' checkedIcon='moon' onChange={toggleDark} />
	    </div>
    </div>
	</Alert>
);


export default DarkModeAlert;
