import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import AnimeWrapper from 'src/interface/vendors/anime-wrapper';
import { Fade } from 'src/interface/basics/animations';
import { ExternalLink } from 'src/interface/basics/links';
import { Mobile, MobileHeader, MobileBrand, MobileBody, MobileItem, MobileBtn, MobileLink } from 'src/interface/basics/mobiles';
import { timeout } from 'src/utils/helpers';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';
import { BrandLogo, BrandLogoDark } from 'src/assets';

const MobileNav = ({ dark, nav, toggleDark, toggleNav }) => {
  const [ navAnimation, setNavAnimation ] = useState('');
  const [ intro, setIntro ] = useState(false);
  const [ path, setPath ] = useState('');
  const location = useLocation();


  // Hooks
  useEffect(() => {
    location.pathname != path ? toggleNav(false) : null;
    setPath(location.pathname);
  }, [location.pathname])


  // Methods
  function introAnimation() {
    setNavAnimation('fade-in-up');
    setIntro(true);
  }

  async function outroAnimation() {
    setIntro(false);
    await timeout(800);
    toggleNav(!nav);
  }


  // Render
	return (
    <Fade show={nav} onEnter={introAnimation} onExit={() => setNavAnimation('fade-out-down')}>
  		<Mobile className={`animate ${navAnimation}`}>
        {nav && (
          <MobileBody className='py-5 px-3'>
            <AnimeWrapper easing='easeOutSine' delay={intro ? 100 : 400} duration={300} opacity={intro ? [0, 1] : [1, 0]} translateY={intro ? ['2rem', '0rem'] : ['0rem', '2rem']}>
              <MobileItem className='pb-4 px-3'>
                <MobileBrand to='/' src={dark ? BrandLogoDark : BrandLogo}/>
              </MobileItem>
            </AnimeWrapper>

            <AnimeWrapper easing='easeOutSine' delay={intro ? 200 : 350} duration={300} opacity={intro ? [0, 1] : [1, 0]} translateY={intro ? ['2rem', '0rem'] : ['0rem', '2rem']}>
              <MobileItem className='py-2'>
              	<MobileLink to='/about' className=''>About us</MobileLink>
              </MobileItem>
            </AnimeWrapper>

            <AnimeWrapper easing='easeOutSine' delay={intro ? 300 : 300} duration={300} opacity={intro ? [0, 1] : [1, 0]} translateY={intro ? ['2rem', '0rem'] : ['0rem', '2rem']}>
              <MobileItem className='py-2'>
              	<MobileLink to='/playbooks' className=''>Our Playbooks</MobileLink>
              </MobileItem>
            </AnimeWrapper>

            <AnimeWrapper easing='easeOutSine' delay={intro ? 400 : 250} duration={300} opacity={intro ? [0, 1] : [1, 0]} translateY={intro ? ['2rem', '0rem'] : ['0rem', '2rem']}>
              <MobileItem className='py-2'>
                <MobileLink to='/services' className=''>Our Services</MobileLink>
              </MobileItem>
            </AnimeWrapper>

            <AnimeWrapper easing='easeOutSine' delay={intro ? 500 : 200} duration={300} opacity={intro ? [0, 1] : [1, 0]} translateY={intro ? ['2rem', '0rem'] : ['0rem', '2rem']}>
              <MobileItem className='py-2'>
              	<MobileLink to='/work' className=''>Our Work</MobileLink>
              </MobileItem>
            </AnimeWrapper>

            <AnimeWrapper easing='easeOutSine' delay={intro ? 600 : 150} duration={300} opacity={intro ? [0, 1] : [1, 0]} translateY={intro ? ['2rem', '0rem'] : ['0rem', '2rem']}>
              <MobileItem className='py-2'>
                <ExternalLink href='https://medium.com/mile-hi-labs' className='btn mobile-btn'>Blog</ExternalLink>
              </MobileItem>
            </AnimeWrapper>

            <AnimeWrapper easing='easeOutSine' delay={intro ? 700 : 100} duration={300} opacity={intro ? [0, 1] : [1, 0]} translateY={intro ? ['2rem', '0rem'] : ['0rem', '2rem']}>
              <MobileItem className='py-2'>
              	<MobileLink to='/contact' className=''>Contact</MobileLink>
              </MobileItem>
            </AnimeWrapper>

            <AnimeWrapper easing='easeOutSine' delay={intro ? 800 : 50} duration={300} opacity={intro ? [0, 1] : [1, 0]} translateY={intro ? ['2rem', '0rem'] : ['0rem', '2rem']}>
              <MobileItem className='py-2'>
                <MobileBtn onClick={() => outroAnimation()} className=''><FalIcon icon='times' /></MobileBtn>
              </MobileItem>
            </AnimeWrapper>
          </MobileBody>
        )}
    	</Mobile>
    </Fade>
	);
}

export default MobileNav;
