import React from 'react';
import Anime from 'react-anime';

const AnimeWrapper = (props) => {
	const { children } = props;

	return (
		<Anime {...props}>{children}</Anime>
	)
}

export default AnimeWrapper;

// Docs
// https://github.com/hyperfuse/react-anime
// https://github.com/hyperfuse/react-anime/blob/master/documentation.md
