import React from 'react';
import { Link } from 'gatsby';
import { ButtonWrapper } from 'src/interface/basics/buttons';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';

const Nav = ({ className = '', children }) => (
	<ul className={`nav ${className}`}>{children}</ul>
)

const NavHeader = ({ className = 'gray-700', children }) => (
	<h6 className={`nav-header ${className}`}>{children}</h6>
)

const NavItem = ({ className = '', children }) => (
	<li className={`nav-item ${className}`}>{children}</li>
)

const NavBtn = ({ taskRunning, onClick, className = '', children }) => (
  <ButtonWrapper onClick={onClick} className={`nav-btn ${className}`}>
    {children}
  </ButtonWrapper>
)

const NavDropBtn = ({ taskRunning, onClick, className = '', children }) => (
  <ButtonWrapper onClick={onClick} className={`nav-btn ${className}`}>
    <span className='flex-between'>{children}<FalIcon icon='arrow-down' className='ml-2'/></span>
  </ButtonWrapper>
)

const NavLink = ({ to, className = '', children }) => (
	<Link to={to} className={`btn nav-btn ${className}`}>
		{children}
	</Link>
)

export { Nav, NavItem, NavHeader, NavBtn, NavDropBtn, NavLink }

// Docs
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/
// https://getbootstrap.com/docs/5.0/components/navs-tabs/
