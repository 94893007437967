import React, { Fragment } from 'react';
import { withDark } from 'src/contexts/dark-context';
import { withNav } from 'src/contexts/nav-context';
import Head from 'src/interface/basics/head';
import MobileNav from 'src/interface/navs/mobile-nav';
import 'src/styles/app.scss';

const MktRoute = (props) => {
	const { darkContext = {}, navContext = {}, children } = props;
	const { dark, toggleDark } = darkContext;
	const { nav, toggleNav } = navContext;

	return (
	  <Fragment>
	    <Head {...props} />
    	<MobileNav dark={dark} nav={nav} toggleDark={toggleDark} toggleNav={toggleNav} />
    	<div className='mkt-route'>
    		{children}
  		</div>
	  </Fragment>
  )
}

export default withDark(withNav(MktRoute));
