import React from 'react';
import { Link } from 'gatsby';
import { ButtonWrapper } from 'src/interface/basics/buttons';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';

const Footer = ({ className = '', children }) => (
	<footer className={className}>
		{children}
	</footer>
)

const FooterHeader = ({ className = '', children }) => (
	<div className={`footer-header ${className}`}>
		{children}
	</div>
)

const FooterBody = ({ className = '', children }) => (
	<div className={`footer-body ${className}`}>
		{children}
	</div>
)

const FooterTitle = ({ className = '', children }) => (
	<h6 className={`footer-title ${className}`}>{children}</h6>
)

const FooterItem = ({ className = '', children }) => (
	<li className={`footer-item ${className}`}>{children}</li>
)

const FooterLink = ({ to, className = '', children }) => (
	<Link to={to} className={`btn footer-link ${className}`}>
		{children}
	</Link>
)

const FooterBtn = ({ ref, taskRunning, onClick, className = '', children }) => (
  <ButtonWrapper ref={ref} onClick={onClick} className={`btn footer-btn ${className}`}>
    {children}
  </ButtonWrapper>
)

const FooterFooter = ({ className = '', children }) => (
	<div className={`footer-footer ${className}`}>
		{children}
	</div>
)

export { Footer, FooterHeader, FooterBody, FooterTitle, FooterItem, FooterBtn, FooterLink, FooterFooter }

// Docs
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/
// https://getbootstrap.com/docs/5.0/components/navs-tabs/
