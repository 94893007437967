import React from 'react';
import { Link } from 'gatsby';
import { ButtonWrapper } from 'src/interface/basics/buttons';

const NavBar = ({ children, className = '' }) => (
	<nav className={'navbar ' + className}>{children}</nav>
);

const NavBarBrand = ({ src, alt = 'Brand logo', className = '' }) => (
	<Link to='/' className={'navbar-brand ' + className}>
		<img src={src} alt={alt}/>
	</Link>
)

const NavBarToggle = ({ active, onClick, className = '' }) => (
	<ButtonWrapper className={`btn-text-gray-800 btn-md hamburger ${active ? 'hamburger--3dxy-r active is-active' : ''}`} onClick={onClick}>
	  <span className='hamburger-box'>
	    <span className='hamburger-inner'></span>
	  </span>
	</ButtonWrapper>
)

const NavbarNav = ({ className= '', children }) => (
	<ul className={`navbar-nav ${className}`}>{children}</ul>
)

export { NavBar, NavBarBrand, NavBarToggle, NavbarNav }

// Docs
// https://react-bootstrap.github.io/components/navbar/
// https://getbootstrap.com/docs/5.0/components/navbar/
