import React, { forwardRef } from 'react';

const Section = forwardRef(({ id = '', className = '', children }, ref) => (
  <section id={id} ref={ref} className={`section-block ${className}`}>
    {children}
  </section>
))

const SectionHeader = ({ className = '', children }) => (
  <div className={`section-header ${className}`}>
    {children}
  </div>
)

const SectionPretitle = ({ className = 'primary', children }) => (
  <h6 className={`section-pretitle ${className}`}>
    {children}
  </h6>
)

const SectionTitle = ({ className = 'gray-800', children }) => (
  <h2 className={`section-title ${className}`}>
    {children}
  </h2>
)

const SectionSubtitle = ({ className = 'gray', children }) => (
  <h5 className={`section-subtitle ${className}`}>
    {children}
  </h5>
)

const SectionText = ({ className = 'gray-700', children }) => (
  <p className={`h5 section-text ${className}`}>
    {children}
  </p>
)

const SectionBtns = ({ className = '', children }) => (
  <div className={`section-btns ${className}`}>
    {children}
  </div>
)

const SectionDivider = ({ className = '' }) => (
  <hr className={`section-divider ${className}`} />
)

const SectionBody = ({ className = '', children }) => (
  <div className={`section-body ${className}`}>
    {children}
  </div>
)

const SectionFooter = ({ className = '', children }) => (
  <div className={`section-footer ${className}`}>
    {children}
  </div>
)

export {
  Section,
  SectionHeader,
  SectionPretitle,
  SectionTitle,
  SectionSubtitle,
  SectionText,
  SectionBtns,
  SectionDivider,
  SectionBody,
  SectionFooter
}
