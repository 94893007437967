import React, { Fragment, useEffect, useState } from 'react';
import { withDark } from 'src/contexts/dark-context';
import { withNav } from 'src/contexts/nav-context';
import DarkModeAlert from 'src/interface/alerts/dark-mode-alert';
import { NavBar, NavBarBrand, NavBarToggle, NavbarNav } from 'src/interface/basics/navbars';
import { NavItem, NavBtn, NavDropBtn, NavLink } from 'src/interface/basics/navs';
import { BtnNextLink, ExternalLink } from 'src/interface/basics/links';
import { SwitchWrapper } from 'src/interface/vendors/switch-wrapper';
import { BrandLogo, BrandLogoDark } from 'src/assets';

const MktNav = (props) => {
  const { darkContext = {}, navContext = {} } = props;
  const { dark, toggleDark } = darkContext;
  const { nav, toggleNav } = navContext;
  const [ mobile, showMobile ] = useState(false);

	return (
    <Fragment>
      <DarkModeAlert dark={dark} title={dark ? 'Switch to light mode.' : 'Switch to dark mode.'} toggleDark={v => toggleDark(v)}/>
  		<NavBar className='flex-between'>
        <NavBarBrand src={dark ? BrandLogoDark : BrandLogo} />
        <NavBarToggle active={nav} onClick={() => toggleNav(!nav)}/>
  			<NavbarNav className='flex-end'>
        	<NavItem><NavLink to='/about'>About</NavLink></NavItem>
          <NavItem><NavLink to='/playbooks'>Our Playbooks</NavLink></NavItem>
          <NavItem><NavLink to='/services'>Our Services</NavLink></NavItem>
          <NavItem><NavLink to='/work'>Our Work</NavLink></NavItem>
          <NavItem><ExternalLink href='https://medium.com/mile-hi-labs' className='btn nav-btn'>Blog</ExternalLink></NavItem>
          <NavItem><BtnNextLink to='/contact' className='btn-primary ml-2'>Contact Us</BtnNextLink></NavItem>
      	</NavbarNav>
    	</NavBar>
    </Fragment>
	);
}

export default withDark(withNav(MktNav));
