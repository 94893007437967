import React, { Fragment } from 'react';
import { Waypoint } from 'react-waypoint';

const WaypointWrapper = (props) => (
	<Waypoint {...props}>
		{props.children}
	</Waypoint>
)

export default WaypointWrapper;

// Docs
// https://github.com/civiccc/react-waypoint
